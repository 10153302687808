.icon
{
    font-size: 50px !important;
    color: #d57315;
}
.banner-title
{
    color: white;
    margin-top: 30px !important;
    font-size: 30px !important;
}
.title-header{ 
    color: #d57315;
    font-size: 30px;
    margin-top: 0px;
  }
    
  @media (max-width: 768px) {
 .title-header{ 
    color: #d57315;
    font-size: 30px;
    margin-top: 200px;
  }
  }
