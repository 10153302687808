
.whats-app {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 15px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 35px;
    z-index: 100;
  }
  
  @media (max-width: 768px) {
    .whats-app {
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 40px;
      right: 20px;
      background-color: #25d366;
      color: #FFF;
      border-radius: 50px;
      text-align: center;
      font-size: 35px;
      z-index: 100;
    }
  }
  /* .my-float {
    margin-top: -1px;
    margin-left: 2px;
  }
   */
  .phone {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 120px;
    right: 15px;
    background-color: #007bff;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 25px;
    z-index: 100;
  }
  @media (max-width: 768px) {
    .phone {
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 120px;
      right: 20px;
      background-color: #007bff;
      color: #FFF;
      border-radius: 50px;
      text-align: center;
      font-size: 25px;
      z-index: 100;
    }
  }
  .enquiry {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 200px;
    right: 15px;
    background-color: #d57315;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
  }
  @media (max-width: 768px) {
    .enquiry {
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 200px;
      right: 15px;
      background-color: #d57315;
      color: #FFF;
      border-radius: 50px;
      text-align: center;
      font-size: 35px;
      z-index: 100;
    }
  } 
  .my-float1 {
    margin-top:17px;
    margin-left: 0px;
  }

  .my-float2{
    margin-top:0px;
    margin-left: 0px;
  }
.copyright {
    text-align: center; 
    padding: 10px;
    margin-top: 20px;
  }
