*{
  padding: 0px;
  margin: 0px;
}

.logo {
  text-align: center;
}

.phoneno
{
  font-size: 16px;
  color: #232323;
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 3px;
}

.title{
  font-size: 32px;
}

.content
{
  color: #8b8b8b;
  font-size: 15px;
}
.btn1
{
  background-color: #d57315;
  color:white;
  font-size: 20px;
  padding:13px 30px;
  border:none;
}
.call
{
  color:#8b8b8b;
  font-size: 15px;
  margin-top: 50px;
}
.contact{
  font-size: 18px;
  color:#232323;
}