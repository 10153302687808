.btn
{
background-color: #f7f7f6 !important;
height: 50px;
width:300px;
margin-top: 5px;
}
@media (max-width: 768px) {
    .btn
{
background-color: #f7f7f6 !important;
height: 50px;
width:385px !important;
margin-top: 5px;
}
     }
   
.btn:hover
{
background-color: #d57315 !important;
height: 50px;
width:300px;
color:white !important;
}

.btn.active {
    background-color: #d37515 !important;
    color:white !important;
}

.title
{
    color: "black";
    font-size: "25px" ;
}
.content
{
    color: #8b8b8b !important;
    padding-right: 120px !important;
    line-height: 30px;
    font-size:18px;
    font-family: sans-serif;
}
@media (max-width: 768px) {
  .content 
{
  /* position: absolute; */
  /* top: 200px; */
    /* color: #8b8b8b !important; */
    /* padding-right: 120px !important; */
    line-height: 30px;
    font-size:18px;
    /* padding-left: 50px; */
    /* background-color:red; */
    /* width: 100vw; */
    /* border: 2px solid black; */
    text-align: left;
    padding-left: 20px;
    font-family: sans-serif;
    /* background-color: pink; */
    
}
   }
.all-height{
    margin-top: -350px !important;
  }
  .all-height1{
    margin-top: 50px !important;
  }
  
  @media (max-width: 768px) {
   .all-height{
    margin-top: -380px !important;
  }
    }
  