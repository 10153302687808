.btn2
{
  background-color: inherit;
  color:#d57315;
  font-size: 17px;
  font-weight: 600;
  border:none;
  text-decoration: none;
}
.all-height{
  margin-top: 50px !important;
}
.all-height1{
  margin-top: 50px !important;
}

@media (max-width: 768px) {
 .all-height{
  margin-top: 50px !important;
}
  }
.content1
{
  line-height: 30px;
  font-size: 16px;
  color:#8b8b8b;
}

.btn1
{
  text-decoration: none;
}