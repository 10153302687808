/* Navbar.css */
.navbar{
    background-color: #232323;
    position: "sticky";
    top: "0";
    z-index: "1000";
}

@media (max-width: 575.98px) {
  .navbar-toggler-icon {
    background-color: white;    
  }
}
/* Styles for mobile devices */

/* Change color of nav links on hover */
.nav-link{
    color:white !important;
  margin-left: 80px;
}
@media (max-width: 768px) {
    .nav-link {
      color: white !important;
      margin-left: 0px;
    }
  }
.nav-link:hover {
    color: #d37515 !important; /* Change to desired hover color */
  }
  
  .dropdown-item:hover {
    background-color: #d37515 !important; /* Change to desired hover background color */
    color: white !important; /* Change to desired hover text color */
  }


  /* Change color of active nav links */
.nav-link.active {
    color: #d37515 !important; /* Change to desired active color */
}

/* Change background color of active dropdown items */
.dropdown-item.active {
  background-color: #d37515 !important; /* Change to desired active background color */
  color: white !important; /* Change to desired active text color */
}

.dropdown-menu {
  left: 75% !important;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .dropdown-menu {
    left: 0% !important;
    transform: translateX(0%);
  }
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
@media (max-width: 575.98px) {
  .navbar-toggler-icon {
    background-color: white;
    margin-left: -10px;
    margin-top: -5px; 
    border-radius: 3px;  
    border-color: #fff;
  }
}
