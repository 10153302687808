.img
{
    width:200px;
    height:200px;
}

.all-height{
    margin-top: 50px !important;
  }
  .all-height1{
    margin-top: 50px !important;
  }
  
  @media (max-width: 768px) {
   .all-height{
    margin-top: 50px !important;
  }
    }
  
    .content1
    {
      line-height: 30px;
      font-size: 16px;
      color:#8b8b8b;
    }